<template>
    <div>
        <el-form label-width="120px" ref="addOrEditModel" :model="addOrEditModel" :rules="addRules">
            <el-form-item label="模板名称" prop="templateName">
                <el-input size="medium" v-model="addOrEditModel.templateName" placeholder="请输入模板名称"></el-input>
            </el-form-item>
            <el-form-item label="问诊类型" prop="consultationTypeId">
                <el-cascader v-model="addOrEditModel.consultationTypeId" :options="searchForm.consultationTypeList"
                    :props="{
                        'value': 'id', 'label': 'typeName'
                    }"></el-cascader>
            </el-form-item>
            <el-form-item label="是否启用" prop="isEnable">
                <el-checkbox v-model="addOrEditModel.isEnable" :true-label="1" :false-label="0"></el-checkbox>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer rigth">
            <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import questionnaireManager from '@/request/questionnaireManager.js'
import systemSetManager from '@/request/systemSetManager.js'
export default {
    mixins: [getList],
    // import引入的组件需要注入到对象中才能使用
    components: {

    },
    props: ['dataInfo'],
    data() {
        // 这里存放数据
        return {
            requst: {
                module: questionnaireManager,
                addUrl: 'saveSurveyQuestionnaireTemplateInfo',
                updateUrl: 'updateSurveyQuestionnaireTemplateInfo',
            },
            query: {
            },


            addOrEditBtnLoading: false,
            addOrEditModel: {
                templateId: 0,
                consultationTypeId: [],
                templateName: '',
                isEnable: 0,
            },
            addRules: {
                templateName: [
                    { required: true, message: '请输入模板名称', trigger: 'blur' },
                ],
                consultationTypeId: [
                    { required: true, message: '请选择问诊类型', trigger: 'blur' },
                ],
            },

            searchForm: {
                consultationTypeList: [],
            },
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.handlePermission()
        if (this.dataInfo) {
            this.addOrEditModel.templateId = this.dataInfo.templateId
            if (this.dataInfo.parentId > 0) {
                this.addOrEditModel.consultationTypeId = [this.dataInfo.parentId, this.dataInfo.consultationTypeId]
            } else {
                this.addOrEditModel.consultationTypeId = [this.dataInfo.consultationTypeId]
            }
            this.addOrEditModel.templateName = this.dataInfo.templateName
            this.addOrEditModel.isEnable = this.dataInfo.isEnable
        }
        if (this.permission.selectConsultationTypeList) {
            this.getConsultationTypeList()
        }
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        async getConsultationTypeList() {
            try {
                let data = await systemSetManager.consultationTypeList({
                    isDelete: 0
                });
                if (data.code == 200) {
                    this.searchForm.consultationTypeList = this.toTree(data.data)
                }
            } catch (error) {
                //
            }
        },
        //处理数据
        toTree(data) {
            let result = []
            if (!Array.isArray(data)) {
                return result
            }
            data.forEach((item) => {
                item.children = ''
            })
            let map = {}
            data.forEach((item) => {
                map[item.id] = item
            })
            data.forEach((item) => {
                let parent = map[item.parentId]
                if (parent) {
                    (parent.children || (parent.children = [])).push(item)
                } else {
                    result.push(item)
                }
            })
            return result
        },



        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        var obj = {
                            templateId: this.addOrEditModel.templateId,
                            consultationTypeId: this.addOrEditModel.consultationTypeId[this.addOrEditModel.consultationTypeId.length - 1],
                            templateName: this.addOrEditModel.templateName,
                            isEnable: this.addOrEditModel.isEnable,
                        }
                        let dataValue = JSON.parse(JSON.stringify(obj))
                        let data = await this.requst.module[this.addOrEditModel.templateId > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success', })
                            this.$emit('addSuccess')
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }
                } else {
                    return false
                }

            })
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped>
.rigth {
    text-align: right;
}
</style>
