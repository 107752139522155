<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请输入模板名称" v-model="query.templateName" clearable=""></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain
                        v-if="permission.saveSurveyQuestionnaireTemplateInfo && permission.selectConsultationTypeList"
                        @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe v-loading="showLoading" :height="tableHeight">
                <el-table-column label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="templateName" label="模版名称" align="left" min-width="120"></el-table-column>
                <el-table-column label="模版问题" align="center" width="90">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showContentHandle(scope.row, 1)"
                            v-if="permission.lookSurveyQuestionnaireTemplateQuestionList">查看</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="typeName" label="问诊类型" align="center" width="100px">
                </el-table-column>
                <el-table-column prop="isEnable" label="是否启用" align="center" width="100px">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isEnable == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center" width="140px">
                    <template slot-scope="scope">
                        {{ scope.row.createTime | formatDate }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="110" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="启用模版" placement="top"
                            v-if="permission.enableTem">
                            <el-popconfirm title="您确定要启用模版么？" icon="el-icon-info" icon-color="red"
                                @confirm="enableTemHandle(scope.row, scope.$index)" v-if="scope.row.isEnable == 0">
                                <el-button icon="el-icon-check" slot="reference" type="text"
                                    class="success"></el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateSurveyQuestionnaireTemplateInfo">
                            <el-button class="success" icon="el-icon-edit-outline" type="text"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeSurveyQuestionnaireTemplateInfo">
                            <el-popconfirm title="您确定要删除么？" icon="el-icon-info" icon-color="red"
                                @confirm="clickDelInfo(scope.row, scope.$index)">
                                <el-button icon="el-icon-delete" slot="reference" type="text"
                                    class="danger"></el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal"></el-pagination>
        </div>
        <el-dialog :title="addOrEditTitle" :visible.sync="dialogAddOrEditVisible" :modal="false"
            :destroy-on-close="true" fullscreen>
            <AddOrEditTemplateInfo v-if="dialogAddOrEditVisible"
                @addSuccess="refreshData(), dialogAddOrEditVisible = false" :dataInfo="addOrEditModel" />
        </el-dialog>

        <el-dialog title="模版问题列表" :visible.sync="dialogTemplateQuestionVisible" :modal="false" :destroy-on-close="true"
            fullscreen>
            <TemplateQuestionList v-if="dialogTemplateQuestionVisible" :templateId="addOrEditModel.templateId" />
        </el-dialog>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import questionnaireManager from '@/request/questionnaireManager.js'
import AddOrEditTemplateInfo from '@/components/page/questionnaire/template/sub/AddOrEditTemplateInfo.vue'
import TemplateQuestionList from '@/components/page/questionnaire/template/sub/TemplateQuestionList.vue'
export default {
    mixins: [getList],
    // import引入的组件需要注入到对象中才能使用
    components: {
        AddOrEditTemplateInfo,
        TemplateQuestionList
    },
    data() {
        // 这里存放数据
        return {
            requst: {
                module: questionnaireManager,
                url: 'surveyQuestionnaireTemplateList',
            },
            query: {
                templateName: '',
                isEnable: '',
            },

            searchForm: {
                isEnableList: [{
                    value: 0,
                    label: '禁用'
                }, {
                    value: 2,
                    label: '启用'
                }],
            },

            // 添加修改
            addOrEditTitle: '',
            addOrEditModel: null,
            dialogAddOrEditVisible: false,
            // 模版问题
            dialogTemplateQuestionVisible: false,
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        onAdd() {
            this.addOrEditTitle = '添加模版'
            this.addOrEditModel = null
            this.dialogAddOrEditVisible = true
        },
        clickEditInfo(row) {
            this.addOrEditTitle = '修改模版'
            this.addOrEditModel = row
            this.dialogAddOrEditVisible = true
        },
        async clickDelInfo(row, index) {
            try {
                let { code, message } = await questionnaireManager.removeSurveyQuestionnaireTemplateInfo({
                    templateId: row.templateId,
                })
                if (code === 200) {
                    this.$message({ message: message, type: 'success', })
                    this.delRefresh(index)
                }
            } catch (error) {
                console.log(error)
            }
        },
        showContentHandle(row, type) {
            switch (type) {
                case 1: {
                    this.addOrEditModel = row
                    this.dialogTemplateQuestionVisible = true
                }
                    break;

                default:
                    break;
            }
        },
        async enableTemHandle(row) {
            try {
                let data = await questionnaireManager.enableTem({ templateId: row.templateId })
                if (data.code == 200) {
                    this.$message.success(data.message)
                    this.refresCurrentPagehData()
                }
            } catch (error) {
                //
            }
        }
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
