<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain
                        v-if="permission.saveSurveyQuestionnaireTemplateQuestionInfo && permission.selectQuestionnaireQuestionList"
                        @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe v-loading="showLoading" :height="tableHeight">
                <el-table-column label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="questionTitle" label="问题标题" align="center" min-width="120"></el-table-column>
                <el-table-column prop="isCoreQue" label="是否核心问题" align="center" width="100px">
                    <template slot-scope="scope">
                        <i :class="[scope.row.isCoreQue == 1 ? 'el-icon-check' : 'el-icon-close']"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="sort" label="排序" align="center" width="100" sortable></el-table-column>
                <el-table-column label="操作" width="90" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateSurveyQuestionnaireTemplateQuestionInfo && permission.selectQuestionnaireQuestionList">
                            <el-button class="success" icon="el-icon-edit-outline" type="text"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeSurveyQuestionnaireTemplateQuestionInfo">
                            <el-popconfirm title="您确定要删除么？" icon="el-icon-info" icon-color="red"
                                @confirm="clickDelInfo(scope.row, scope.$index)">
                                <el-button icon="el-icon-delete" slot="reference" type="text"
                                    class="danger"></el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination> -->
        </div>

        <el-dialog :title="addOrEditTitle" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true"
            fullscreen @close="resetAddOrEditData">
            <el-form label-width="120px" ref="addOrEditModel" :model="addOrEditModel" :rules="addRules">
                <el-form-item label="问题：" prop="questionId">
                    <el-button style="margin-bottom:10px" @click="selectQuestionHandle">选择问题</el-button>
                    <el-input size="small" v-model="addOrEditModel.questionTitle" placeholder="请选择问题"
                        disabled></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input size="medium" v-model="addOrEditModel.sort" placeholder="请输入排序"
                        @input="addOrEditModel.sort = addOrEditModel.sort.replace(/[^0-9]/g, '')"></el-input>
                </el-form-item>
                <el-form-item label="是否核心问题" prop="isCoreQue">
                    <el-checkbox v-model="addOrEditModel.isCoreQue" :true-label="1" :false-label="0"></el-checkbox>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="选择问题" :visible.sync="dialogSelectQuestionListVisible" :modal="false" :destroy-on-close="true"
            class="absolute" fullscreen>
            <QuestionnaireQuestionsList @changeSelectQuestion="changeQuestionHandle" v-if="dialogSelectQuestionListVisible" />
        </el-dialog>

    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import questionnaireManager from '@/request/questionnaireManager.js'
import QuestionnaireQuestionsList from '@/components/page/questionnaire/question/QuestionList.vue'
export default {
    mixins: [getList],
    props: ['templateId'],
    // import引入的组件需要注入到对象中才能使用
    components: {
        QuestionnaireQuestionsList
    },
    data() {
        // 这里存放数据
        return {
            requst: {
                module: questionnaireManager,
                url: 'surveyQuestionnaireTemplateQuestionList',
                addUrl: 'saveSurveyQuestionnaireTemplateQuestionInfo',
                updateUrl: 'updateSurveyQuestionnaireTemplateQuestionInfo',
            },
            query: {
                templateId: this.templateId
            },
            //添加
            addOrEditDialog: false,
            addOrEditTitle: '',
            addOrEditBtnLoading: false,
            addOrEditModel: {
                id: 0,
                templateId: this.templateId,
                questionId: '',
                questionTitle: '',
                sort: '',
                isCoreQue: 0
            },
            addRules: {
                questionId: [
                    { required: true, message: '请选择问题', trigger: 'cahnge' },
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' },
                ],
            },


            dialogSelectQuestionListVisible: false,

        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.query.offset = null
        this.query.limit = null
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        onAdd() {
            this.addOrEditTitle = '添加问题'
            this.addOrEditDialog = true
        },
        clickAddOrEditCancel() {
            this.resetAddOrEditData()
        },
        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.addOrEditModel))
                        let data = await this.requst.module[this.addOrEditModel.id > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success', })
                            this.refresCurrentPagehData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }
                } else {
                    return false
                }
            })
        },
        resetAddOrEditData() {
            this.addOrEditDialog = false
            this.addOrEditModel = {
                id: 0,
                templateId: this.templateId,
                questionId: '',
                questionTitle: '',
                sort: '',
                isCoreQue: 0
            }
        },
        async clickEditInfo(row) {
            this.addOrEditModel = {
                id: row.id,
                templateId: this.templateId,
                questionId: row.questionId,
                questionTitle: row.questionTitle,
                sort: row.sort,
                isCoreQue: row.isCoreQue,
            }
            this.addOrEditTitle = '修改问题'
            this.addOrEditDialog = true
        },
        async clickDelInfo(row, index) {
            try {
                let { code, message } = await questionnaireManager.removeSurveyQuestionnaireTemplateQuestionInfo({
                    id: row.id,
                })
                if (code == 200) {
                    this.$message({ message: message, type: 'success', })
                    this.delRefresh(index)
                }
            } catch (error) {
                console.log(error)
            }
        },

        selectQuestionHandle() {
            this.dialogSelectQuestionListVisible = true
        },
        changeQuestionHandle(row) {
            var arr = this.tableData.filter(obj => {
                return obj.questionId == row.id
            })
            if (arr.length == 0) {
                this.addOrEditModel.questionId = row.id
                this.addOrEditModel.questionTitle = row.questionTitle
                this.dialogSelectQuestionListVisible = false
            } else {
                this.$message.error('选择的问题重复')
            }
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
