var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "addOrEditModel",
          attrs: {
            "label-width": "120px",
            model: _vm.addOrEditModel,
            rules: _vm.addRules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "模板名称", prop: "templateName" } },
            [
              _c("el-input", {
                attrs: { size: "medium", placeholder: "请输入模板名称" },
                model: {
                  value: _vm.addOrEditModel.templateName,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "templateName", $$v)
                  },
                  expression: "addOrEditModel.templateName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "问诊类型", prop: "consultationTypeId" } },
            [
              _c("el-cascader", {
                attrs: {
                  options: _vm.searchForm.consultationTypeList,
                  props: {
                    value: "id",
                    label: "typeName",
                  },
                },
                model: {
                  value: _vm.addOrEditModel.consultationTypeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "consultationTypeId", $$v)
                  },
                  expression: "addOrEditModel.consultationTypeId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否启用", prop: "isEnable" } },
            [
              _c("el-checkbox", {
                attrs: { "true-label": 1, "false-label": 0 },
                model: {
                  value: _vm.addOrEditModel.isEnable,
                  callback: function ($$v) {
                    _vm.$set(_vm.addOrEditModel, "isEnable", $$v)
                  },
                  expression: "addOrEditModel.isEnable",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer rigth",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
              on: { click: _vm.clickAddOrEditConfirm },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }